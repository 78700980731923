import { JOBS_PER_PAGE, routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { makeSlug } from '/machinery/makeSlug'
import { JobCard } from '/features/buildingBlocks/JobCard'
import { JobAlert } from '/features/pageOnly/jobs/JobAlert'
import { NoResultsCard } from '/features/pageOnly/jobs/NoResultsCard'
import { Pagination } from '/features/buildingBlocks/Pagination'
import { getSalaryRange } from '/machinery/getSalaryRange'
import { mapJobData } from '/machinery/mapJobData'

import styles from './Results.css'

export function Results({ page, jobs, totalJobs, isSuccess, isFetching, onPageChange, onResetClick }) {
  const { __ } = useTranslate()
  const maxPages = Math.ceil(totalJobs / JOBS_PER_PAGE)

  return (
    <div  className={cx(styles.component, isFetching && styles.isLoading)}>
      {isSuccess && Boolean(jobs.length)
        ? <JobsList {...{ jobs, totalJobs }} />
        : (
          <div className={styles.noResults}>
            <NoResultsCard
              title={__`no-jobs-found`}
              dataX='clear-jobs-filters'
              {...{ onResetClick }}
            />
            <JobAlert />
          </div>
        )
      }
      {totalJobs >= 1 &&
      <div data-style-context='light' className={styles.pagination}>
        <Pagination
          currentPage={page}
          {... { maxPages, onPageChange }}
        />
      </div>}
    </div>
  )
}

function JobsList({ jobs }) {
  const { __ } = useTranslate()
  const language = useLanguage()

  return (
    <div className={styles.componentJobsList}>
      <ul className={styles.jobsList}>
        {jobs?.map(({ _source: job }, index) => {
          const {
            id,
            title,
            city,
            countryCode,
            contractType,
            remoteType,
            jobBranches,
            salaryCurrency,
            salaryMax,
            salaryMin,
            hoursMax,
            dateStart
          } = mapJobData({ job, lang: language })

          const href = routeMap.app.job({
            language,
            jobId: id,
            jobTitle: makeSlug({ input: job.job_title, language: job.job_language })
          })

          const branches = jobBranches?.length > 0
            ? jobBranches.map(b => b?.[`label_${language}`] ?? b?.label_en ?? null).filter(Boolean)
            : []

          const labels = [
            ...(remoteType) ? [{ title: remoteType }] : [],
            { title: countryCode ? `${city} (${countryCode.toUpperCase()})` : city },
            ...(salaryMax && salaryMin) ? [ { title: getSalaryRange({ locale: language, salaryMin, salaryMax, salaryCurrency }) } ] : [],
            ...(hoursMax > 0) ? [{ title: __({ hours: hoursMax })`job-hours-max` }] : [],
            ...(contractType) ? [{ title: contractType }] : [],
          ]

          return (
            <React.Fragment key={id}>
              <li>
                <JobCard
                  {...{ title, labels, href, dateStart, branches, id }}
                />
              </li>

              {(index === 4 || (index < 4 && index === jobs.length - 1)) && (
                <li>
                  <JobAlert />
                </li>
              )}
            </React.Fragment>
          )
        })}

      </ul>
    </div>
  )
}
